import React from "react";
// import classNames from "classnames";
import { ContactList, TagList } from "@molecules";
import { Container, Text, Image, ButtonAlt, AnimateIn } from "@atoms";

const Hero = ({ heading, image, backLink, contact, tags }) => {
  return (
    <section className="relative flex flex-col pt-12 md:pt-16">
      <Container>
        <div className="flex flex-col gap-12 md:gap-20">
          <div className="relative z-10 flex flex-col gap-12 md:gap-16">
            {backLink && (
              <ButtonAlt to={backLink} reverse>
                Return
              </ButtonAlt>
            )}
            <AnimateIn
              preset="fadeUpFast"
              className="flex flex-wrap items-center gap-8 sm:gap-12"
            >
              {image && (
                <div className="relative size-36 shrink-0 overflow-hidden rounded-full sm:size-44">
                  <Image
                    image={image}
                    ars={{ xs: [1, 1] }}
                    sizes={{ xs: 0.4, sm: 0.25 }}
                    fill
                    className="rounded-full"
                  />
                </div>
              )}
              <div className="relative sm:-top-0.5 sm:flex-1">
                <Text
                  variant="headingSm"
                  className="bg-heading inline whitespace-pre-line"
                >
                  {heading}
                </Text>
              </div>
            </AnimateIn>
          </div>
          {!!contact?.length && <ContactList items={contact} />}
          {!!tags?.length && <TagList tags={tags} />}
        </div>
      </Container>
    </section>
  );
};

export default Hero;

Hero.defaultProps = {};
